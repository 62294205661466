<script lang="ts" setup>
import FeatureProducts from "~/components/home/FeatureProducts.vue";
import H1Heading from "~/components/H1Heading.vue";
import ButtonSecondary from "~/components/ButtonSecondary.vue";
import WhatNewAtABC from "~/components/home/WhatNewAtABC.vue";
import ResourceSlides from "~/components/home/ResourceSlides.vue";
import WhatCustomersSay from "~/components/home/WhatCustomersSay.vue";
import RecentlyViewed from "~/components/home/RecentlyViewed.vue";
import CredentialLogos from "~/components/home/CredentialLogos.vue";
import SalesTeam from "~/components/home/SalesTeam.vue";
import H2Heading from "~/components/H2Heading.vue";
import { STATE_CONTENTS } from "~/constants/stateContents";

// Properties
const props = defineProps({
    state: {
        type: String,
        default: "ga",
    },
});

// Data
const userState = ref(props.state?.toLowerCase());

const stateContents = ref(STATE_CONTENTS[userState.value.toUpperCase()] ?? STATE_CONTENTS["DEFAULT"]);
// Functions
</script>

<template>
    <div>
        <!-- Section: Meeting standards -->
        <div class="container mt-10 grid-cols-2 gap-x-20 px-0 sm:px-8 lg:grid">
            <div class="items-center lg:flex">
                <div class="px-4 pt-6 text-center lg:flex-1 lg:pt-0 lg:text-left">
                    <H1Heading size="text-4xl md:text-5xl" line-height="md:leading-tight">
                        Meeting Standards,<br class="hidden lg:inline" />
                        Exceeding Expectations
                    </H1Heading>
                    <div class="mb-4 mt-4 leading-6" v-html="stateContents.hero"></div>
                    <ButtonSecondary type="link" :href="`/products/state/${userState}`" class="mb-4">
                        Explore Workbooks
                    </ButtonSecondary>
                </div>
            </div>
            <div class="">
                <NuxtImg
                    src="/images/hero_desk.png"
                    class="ml-auto hidden lg:block"
                    format="webp"
                    alt="Hero Desk"
                    sizes="570px, xl:680px"
                    fit="inside"
                />
                <NuxtImg class="mt-12 w-full lg:hidden" src="/images/hero_mobile.png" format="webp" alt="Hero Mobile" />
            </div>
        </div>

        <!-- Section: Why teach with ABC -->
        <div class="bg-gray-100">
            <div class="container pt-10 md:pt-14">
                <H2Heading size="text-3xl md:text-4xl" class="mb-2 text-center">Why teach with ABC?</H2Heading>
                <p class="mx-auto mb-16 w-4/6 text-center leading-6">
                    For nearly 30 years, ABC has been making solutions designed to fit seamlessly into your instruction.
                    Our pretest and Chart of Standards allow quick identification of individual learning gaps, every
                    chapter contains excellent instructional content and practice via review questions, and a posttest
                    is included to confirm mastery of all concepts. Each order includes student workbooks, a free
                    teacher’s guide, and access to our CourseWave digital platform for eBooks and online testing.
                </p>

                <div class="md:flex">
                    <div class="mx-4 flex-1 rounded-xl border bg-white p-4 text-center drop-shadow">
                        <div class="mx-auto -mt-14 mb-6 h-20 w-20 rounded-full bg-abc-yellow">
                            <NuxtImg src="/images/icons/print.svg" class="inline" alt="Printed Workbooks" />
                        </div>
                        <h3 class="mb-2 text-lg font-semibold">Printed Workbooks</h3>
                        <p>
                            All ABC content maps directly to state blueprints. The questions on the full-length pre and
                            posttests simulate state assessments, and are labeled with DOK level and standard for easy
                            concept identification. Your included teacher’s guide is not only the answer key for all
                            tests and quizzes, but also offers additional reproducible assessments not included in the
                            student workbook.
                        </p>
                    </div>
                    <div class="mx-4 mt-4 flex-none items-center text-center md:mt-0 md:flex">
                        <NuxtImg src="/images/icons/plus.svg" class="inline w-8" alt="Plus" />
                    </div>
                    <div class="mx-4 mt-12 flex-1 rounded-xl border bg-white p-4 text-center drop-shadow md:mt-0">
                        <div class="mx-auto -mt-14 mb-6 h-20 w-20 rounded-full bg-abc-purple">
                            <NuxtImg src="/images/icons/digital.svg" class="inline" alt="Digital Workbooks" />
                        </div>
                        <h3 class="mb-2 text-lg font-semibold">Digital Workbooks</h3>
                        <p>
                            Our eBook content matches our print workbooks exactly, ensuring a seamless experience for
                            students whether they are in the classroom or at home.
                        </p>
                    </div>
                    <div class="mx-4 mt-4 flex-none items-center text-center md:mt-0 md:flex">
                        <NuxtImg src="/images/icons/plus.svg" class="inline w-8" alt="Plus" />
                    </div>
                    <div class="mx-4 mt-12 flex-1 rounded-xl border bg-white p-4 text-center drop-shadow md:mt-0">
                        <div class="mx-auto -mt-14 mb-6 h-20 w-20 rounded-full bg-abc-green">
                            <NuxtImg src="/images/icons/online_test.svg" class="inline" alt="Online Testing" />
                        </div>
                        <h3 class="mb-2 text-lg font-semibold">Online Testing</h3>
                        <p>
                            Built-in analytics and instant score reporting give teachers the data they need to identify
                            learning gaps at the student or classroom level while students gain familiarity and
                            confidence with online testing platforms in addition to the tested material.
                        </p>
                    </div>
                </div>

                <div class="py-10 text-center">
                    <ButtonSecondary type="link" href="/about-abc">Learn More About ABC</ButtonSecondary>
                </div>
            </div>
        </div>

        <div class="container pt-10">
            <FeatureProducts class="pb-8" :user-state="userState" />

            <WhatCustomersSay :user-state="userState" class="pb-10" />
            <ResourceSlides :user-state="userState" class="pb-8" />
            <WhatNewAtABC class="pb-6 lg:pb-12" />

            <RecentlyViewed />

            <hr class="mb-2 mt-6 border-gray-400" />

            <CredentialLogos class="py-10" />
            <SalesTeam :state="userState" />
        </div>
    </div>
</template>

<style scoped></style>
