<script lang="ts" setup></script>

<template>
    <div>
        <HrWithText class="mb-4">
            <h2 class="text-2xl font-semibold">What's New at ABC</h2>
        </HrWithText>
        <div class="lg:flex">
            <div class="with-bg-image flex flex-none items-center justify-center p-4 lg:h-80 lg:w-1/2">
                <NuxtImg
                    src="/images/home/AdobeStock_290177539-1080x540.jpeg"
                    class="w-4/6 overflow-hidden rounded-2xl lg:w-5/6 xl:w-3/5"
                />
            </div>
            <div class="flex flex-1 items-center justify-center px-12 py-6">
                <div class="flex-1">
                    <h3 class="mb-2 mt-4 text-xl font-medium md:mt-4">
                        Build a Lifelong Love of Reading Over Summer Break
                    </h3>
                    <p>
                        With Summer break hurtling closer, kids everywhere are looking ahead to sunny summer vacation
                        days, and schools are publishing summer reading lists. These are the years when children start
                        to become lifelong readers - and educators and parents can help that process!
                    </p>
                    <div class="mt-4 hidden">
                        <NuxtLink to="#" class="text-abc-blue">Continue Reading</NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.with-bg-image {
    background-image: url("/images/home/zone4_bg_530x360.svg");

    @apply bg-cover bg-center bg-no-repeat lg:bg-auto;
}
</style>
